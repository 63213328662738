import {listen, prefetch} from 'quicklink';

window.addEventListener('load', e => {
  listen({
    ignores: [
      uri => uri.includes('#'), // Ignore any link with a hash/anchor
      uri => uri.includes('.pdf'),
      uri => uri.includes('.doc'),
      uri => uri.includes('docx'),
      uri => uri.includes('xls'),
      uri => uri.includes('xlsx'),
      uri => uri.includes('mp3'),
      uri => uri.includes('wav'),
      uri => uri.includes('zip'),
      uri => uri.includes('odt'),
      uri => uri.includes('odf'),
      uri => uri.includes('ods'),
      (uri, elem) => elem.hasAttribute('noprefetch'),
    ]
  });
});
